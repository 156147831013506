body{
  font-family: "Rubik", sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.clear{
  clear: both;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Align Nav Items in a row */
.nav-item-inline {
  display: flex;
  align-items: center;
  justify-content: start;
  font-size: 16px;
  /* margin-bottom: 10px; */
}

/* Remove underline from NavLink's a tag */
.nav-link {
  text-decoration: none;
  color: inherit;
}

/* On hover or active state, you can change the link color */
.nav-link:hover, .nav-link:active {
  color: #000;
}

/* Change bi-circle to bi-circle-fill and make it green on active */
/* Align Nav Items in a row */
.nav-item-inline {
  display: flex;
  align-items: center;
  justify-content: start;
}

/* Style for the NavLink to align icon and text horizontally */
.nav-link {
  display: flex;
  align-items: center;
  text-decoration: none;  /* Remove underline from links */
  color: inherit;         /* Inherit the text color */
}

/* Change bi-circle to bi-circle-fill and make it green on active */
.active-icon {
  color: #59B58D; /* Change this to the shade of green you prefer */
}

.nonactive-icon {
  color: #D9D9D9; /* Change this to the shade of green you prefer */
}

/* Remove any default margin/padding from <p> tags within nav-link */
.nav-link p {
  margin: 0;
  padding: 0;
  display: inline-block;
  margin-right: 5px;
  font-size: 16px;
  font-weight: 400;
}

.nav-icon{
  font-size: 30px;
}

.username{
  font-size: 20px;
  font-weight: 500;
  margin-right: 18px;
  color: #425154;
  margin-bottom: 0;
}

/* Change unchecked switch to grey */
.custom-switch .form-check-input {
  background-color: #ccc !important;  /* Grey background when unchecked */
  border-color: #ccc !important;
}

/* Change checked switch to green */
.custom-switch .form-check-input:checked {
  background-color: #59B58D !important;  /* Green when checked */
  border-color: #59B58D !important;
}

/* Optional: Change the switch handle (knob) color */
.custom-switch .form-check-input:focus {
  box-shadow: none;  /* Removes blue focus shadow */
}


.switch-box {
  display: inline-flex;
  align-items: center;
  background-color: #f8f9fa; /* Light grey background */
  border-radius: 10px;
  padding: 5px 5px;
  margin-right: 15px;
}

.switch-box .form-check {
  margin-bottom: 0;
}

.switch-box .form-check-label {
  margin-left: 2px;
  font-size: 1rem; /* Slightly smaller label */
}

.switch-box .form-check-input {
  transform: scale(1.4); /* Smaller switch size */
}

.switch-box span {
  font-size: 1.3rem; /* Slightly smaller label */
}

.form-check-input:checked{
  background-color: #59B58D;
  border-color: #59B58D;
}

.btn-success, .btn-success:hover, .btn-success.active{
  background-color: #59B58D;
  border-color: #59B58D;
}

.form-control{
  background-color: #F2F2F7;
}
.form-control:focus{
  box-shadow: none;
  border: 2px solid #2684ff!important;
}
#password{
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  border-left: 0;
  border-bottom-right-radius: .375rem;
  border-top-right-radius: .375rem;
}

.input-group-text{
  /* border-right: 0px; */
  background-color: #F2F2F7;
  border-radius: 0px !important;
  border-bottom-right-radius: .375rem !important;
  border-top-right-radius: .375rem !important;
  border-left: 0px !important;
  /* border-bottom-left-radius: .375rem !important;
  border-top-left-radius: .375rem !important; */
}

.card{
  background-color: #F2F2F7;
  border: none;
}

.switch-box{
  background-color: #E0F8ED;
}


.split {
  height: 100%;
  width: 50%;
  position: fixed;
  z-index: 1;
  overflow-x: hidden;
  padding-top: 20px;
}

.left {
  left: 0;
  background-color: #F2F2F7;
}

.right {
  right: 0;
  background-color: #ffff;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
}

.navcolour{
  background-color: #F2F2F7;
}


.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback){
  margin-left: calc(var(--bs-border-width)* -1);
    border-top-left-radius: .375rem;
    border-bottom-left-radius: .375rem;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.viewpasswordlabel{
  border-bottom-left-radius: .375rem !important;
  border-top-left-radius: .375rem !important;
  border-right: 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.newselect .css-13cymwt-control{
  background-color: #F2F2F7;
  border-radius: 0.375rem;
  border: 1px solid #dee2e6;
}

.modal-header{
  border-bottom: 0px;
}
.modal-footer{
  border-top: 0px;
}

.text-grey{
  color: #9EA3AE;
}

@media (max-width: 767px){
  .right{
    width:100%;
  }
  .centered{
    width:90%;
  }
}

.bksubp{
  justify-content: flex-start !important;
}

.customcheckbox:focus,.form-check-input:focus{
  box-shadow: none;
}

.bg-light{
  background-color: #f2f2f7 !important;
  border-color: #f2f2f7;
}

.css-1u9des2-indicatorSeparator{
  display: none;
}

.close_btn,.close_btn:hover{
  background-color: #c4c4c4!important;
  border-color: #c4c4c4!important;
  color: #fff;
}
.close_btn:active{
  background-color: #e2e6ea!important;
  border-color: #e2e6ea!important;
  color: #000;
}

.displayinline{
  display: inline;
}

.select-button{
  background-color: #f2f2f7 !important;
  border-color: #f2f2f7 !important;
  color: #000 !important;
}

.dashboard-card{
  font-weight: 700;
  font-size: 35px;
  color: #535353;
  line-height: 41.48px;
}

.sign_text{
  color: #535353;
  font-size: 20px;
  display: inline;
}

.excel_text{
  font-size: 14px;
  font-weight: 400;
  color: #808080;
}

.custom-switch{
  padding-left: 3em;
}

.form-switch .form-check-input,.form-switch .form-check-input:focus{
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e");
}

.nav-tabs{
  border-color: transparent;
}

.nav-tabs .nav-link{
  border: none;
  border-bottom: 2px solid #dee2e6;
  padding-left: 20px;
  padding-right: 20px;
}
.nav-tabs .nav-link.active{
  font-weight: bold;
  border: none;
  border-bottom: 2px solid #000!important;
}
.nav-tabs .nav-link:hover{
  border: none;
  border-bottom: 2px solid #000!important;
}
.nav-tabs .nav-link:focus{
  border-color: transparent;
  border-bottom-color: #000;
}
.table_heading{
  font-size: 16px;
  font-weight: 400;
}
.detail_heading,.table_heading > td{
  color: #808080;
}
.table_detail{
  font-size: 18px;
  font-weight: 400;
}

.login_sub{
  color: #808494;
}
.button_font{
  font-size: 16px;
}
.button_font:hover{
  border-color: #f2f2f7;
}
.excel_img{
  height: 15px;
}
.bi-caret-down-fill{
  color: #969696;
}
.cursor-pointer{
  cursor: pointer;
}
.bi-caret-down-fill,.bi-person-circle{
  color: #969696;
}
.css-tj5bde-Svg{
  color: #000;
}
.bi-caret-down-fill{
  padding-right: .25rem;
}
.form-check-input:focus{
  border-color: #DEE2E6;
}
.rdt_Pagination{
  justify-content: center!important;
}
.sidebar{
  min-height: calc(100vh - 55px)!important;
}
.sidebar-sticky{
  margin-top: 22px;
}
.arrow_right{
  padding-left: 25px !important;
  padding-top: 20px !important;
}
.logout_btn{
  position: fixed;
  bottom: 0;
}
.text-warning{
  color: #ff9900!important;
}
.text-danger{
  color: #ff0000!important;
}
.text-primary{
  color: #0038ff!important;
}
.text-info{
  color: #aa57c7!important;
}
.text-success{
  color: #107C41!important;
}
.text-secondary{
  color: #425154!important;
}
.dashboard_card_block{
  width: 211px;
  height: 118px;
  background-color: #F2F2F7;
  display: flex;
  justify-content: center;
  align-items: center; 
  flex-direction: column;
  margin-bottom: 20px;
  border-radius: 6px;
  margin-left: 20px;
}
.dashboard-card_subtext{
  font-size: 20px;
  font-weight: 400;
  color: #535353;
  line-height: 23.7px;
}
.report_card{
  font-weight: 700;
  font-size: 35px;
  color: #535353;
  line-height: 37.92px;
}
.report_card_block{
  width: 211px;
  height: 86px;
  background-color: #F2F2F7;
  display: flex;
  justify-content: center;
  align-items: center; 
  flex-direction: column;
  margin-bottom: 20px;
  border-radius: 6px;
  margin-left: 20px; 
}
.report_card_subtext{
  font-size: 16px;
  font-weight: 400;
  color: #535353;
  line-height: 18.96px;
}
.gap-1{
  display: flex;
  justify-content: center;
}
.active_pagination{
  background-color: #59B58D!important;
  border-radius: 23px;
  height: 32px;
  width: 32px;
  padding: 5px 2px 8px 2px !important;
  font-size: 14px;
  border: none;
}
.pagination_btn{
  background-color: transparent;
  border: none;
}
.pagination_text{
  position: absolute;
  top: 17px;
}
.btn-outline-danger{
  color: #ff0000!important;
  border-color: #ff0000!important;
}
.btn-outline-danger:hover{
  color: #fff!important;
}
.transparent_button{
  background-color: transparent!important;
  font-size: 24px;
  color: #808080!important;
}
.remove_bold{
  font-weight: 400;
  font-size: 24px;
}